enum ORIENTATIONS {
  LANDSCAPE = "landscape",
  LANDSCAPE_PRIMARY = "landscape-primary",
  LANDSCAPE_SECONDARY = "landscape-secondary",
  PORTAIT = "portrait",
  PORTAIT_PRIMARY = "portrait-primary",
  PORTAIT_SECONDARY = "portrait-secondary"
}

export default ORIENTATIONS
